import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaGithub, FaLinkedin, FaTwitter, FaCameraRetro  } from "react-icons/fa";
import { SiHackthebox } from "react-icons/si";
import { IoIosMailUnread } from "react-icons/io";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconType } from 'react-icons';

const linkMap = {
  GitHub: "https://github.com/JamesMatchett",
  HTB: "",
  LinkedIn: "https://uk.linkedin.com/in/jmatch",
  Twitter: "https://x.com/JMMatchett",
  Email: "mailto://info@jamesmatchett.co.uk",
  Photography: "https://www.instagram.com/jmatchpics/"
}


const tooltip = function(name:string){
  return(
  <Tooltip id="tooltip">
    <strong>{name}</strong>
  </Tooltip>)
};


function NavScrollExample() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark" fixed="bottom">
      <Container fluid>
        <Navbar.Brand href="#">Socials</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >

            <OverlayTrigger placement="top" overlay={tooltip("Github")}>
              <Nav.Link href={linkMap.GitHub} target="_blank">
                <FaGithub/>
              </Nav.Link>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={tooltip("HackTheBox")}>
              <Nav.Link href={linkMap.HTB} target="_blank">
                <SiHackthebox />
              </Nav.Link>
            </OverlayTrigger>


            <OverlayTrigger placement="top" overlay={tooltip("LinkedIn")}>
              <Nav.Link href={linkMap.LinkedIn} target="_blank">
                <FaLinkedin />
              </Nav.Link>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={tooltip("Twitter")}>
              <Nav.Link href={linkMap.Twitter} target="_blank">
                <FaTwitter />
              </Nav.Link>
            </OverlayTrigger>


            <OverlayTrigger placement="top" overlay={tooltip("Email Me!")}>
              <Nav.Link href={linkMap.Email} target="_blank">
                <IoIosMailUnread />
              </Nav.Link>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={tooltip("Photography")}>
              <Nav.Link href={linkMap.Photography} target="_blank">
                <FaCameraRetro />
              </Nav.Link>
            </OverlayTrigger>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;