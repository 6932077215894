import Row from 'react-bootstrap/Row';
import SpeakersSession from '../../img/SpeakersSession.jpg'
import NIDC from '../../img/NIDC.png'
import hth from '../../img/hth.jpg'
import speaker2 from '../../img/Speaker2.jpg'
import Carousel from 'react-bootstrap/Carousel';

function CarouselHomePage(){

    return(
    <div className='restrictWidth'>
    <div className='carouselContainer'>
        <Row md={1} xs={1}>

            <Carousel className='carouselSelf'>
                <Carousel.Item className="carouselItem">
                    <img src={hth} className="carouselImageHTH d-block min-vh-100 w=100" />

                    <Carousel.Caption className='carouselCaption'>
                        <h3>$Whoami?</h3>
                        <p>I'm a Cyber Security Engineer originally from Belfast, now living in London</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="carouselItem">
                    <img src={NIDC} className="carouselImage d-block min-vh-100 w=100" />

                    <Carousel.Caption className='carouselCaption'>
                        <h3>What do I do?</h3>
                        <p>I enjoy Penetration testing, Cloud & AppSec, Electronics, Photography, Hackathons and Organising community tech events!</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="carouselItem" >
                    <img src={speaker2} className="carouselImage d-block min-vh-100 w=100" />

                    <Carousel.Caption className='carouselCaption'>
                        <h3>What is this site?</h3>
                        <p>
                            This site is a collection of "things I've been up to" as well as some useful guides by an engineer who's been there, that can hopefully help other people!
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Row>
    </div>
</div>
    )
}

export default CarouselHomePage;
