import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './HomePage.css'
import projects from '../../img/projects.jpg'
import CarouselHomePage from './CaourselHomePage';


function HomePage() {
    return (

        <div>
            <div className='helloDivider'>
                <h2 className='hello helloTitleBold'>
                    Hello,
                </h2>
                <h2 className='hello helloTitleBold'>
                    I'm James
                </h2>
                <hr className='HRStyling'/>
            </div>


            <div className='restrictWidth'>
                <div className='carouselContainer'>
                    <CarouselHomePage/>
                    <hr/>
                </div>
            </div>

            <div className='restrictWidth'>
                <Row md={2} xs={1}>
                    <Col>
                        <Card className='cardSpacing'>
                            <Card.Img variant="top" src={projects} />
                            <Card.Body>
                                <Card.Title>Projects!</Card.Title>
                                <Card.Text>
                                    This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit
                                    longer.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col >
                        <Card className='cardSpacing'>
                            <Card.Img variant="top" src={projects} />
                            <Card.Body>
                                <Card.Title>Blog posts & Tutorials</Card.Title>
                                <Card.Text>
                                    This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit
                                    longer.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col >
                        <Card className='cardSpacing'>
                            <Card.Img variant="top" src={projects} />
                            <Card.Body>
                                <Card.Title>What I Do</Card.Title>
                                <Card.Text>
                                    This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit
                                    longer.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col >
                        <Card className='cardSpacing'>
                            <Card.Img variant="top" src={projects} />
                            <Card.Body>
                                <Card.Title>Photography</Card.Title>
                                <Card.Text>
                                    This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit
                                    longer.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            <div className='helloDivider'>
                <hr />
            </div>
        </div>

    );
}

export default HomePage;